import Vue from "vue";
import Vuetify from "vuetify/lib";
//import 'vuetify/dist/vuetify.min.css';
import Moon from '../components/icons/IconMoon.vue';
import Sun from "../components/icons/IconSun.vue";
// import indeterminateCheckBox from '../assets/images/icons/indeterminate-check-box-icon.svg';
import miniLogo from "../components/icons/MiniLogo.vue";

import { Scroll } from "vuetify/lib/directives";

Vue.use(Vuetify, {
	directives: {
		Scroll,
	},
});

const customIcons = {
	// checkBox,
	// checkBoxOutline,
	// indeterminateCheckBox,
	miniLogo: {
		component: miniLogo,
	},
	sun: {
		component: Sun,
	},
	moon: {
		component: Moon,
	},
};

const themeColors = {
	primary: "#04BF7B",
	secondary: "#F27C38",
	tooltip: "#ff8438",
	accent: "#3d7dd5",
	error: "#e91e63",
};

export default new Vuetify({
	icons: {
		iconfont: "md",
		values: customIcons,
	},
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: themeColors,
			dark: themeColors,
		},
	},
});
