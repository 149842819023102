export const snackbarModule = {

    state: {
        snack: '',
        type: '',
        snackOptions: null
    },

    namespaced: true,

    mutations: {
        setSnack(state, snack) {
            state.snack = snack
        },
        setSnackOptions(state, snackOptions) {
            state.snackOptions = snackOptions
        },
        setSnackType(state, type) {
            state.type = type
        }
    }

}