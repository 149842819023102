import { TariffPlansService } from 'api';
import tariffPlansDefaultState from 'defaultStates/tariffPlans'

import {
    SET_AVAILABLE_TARIFF_PLANS
} from '../mutations.type'

export const tariffPlanModule = {

    namespaced: true,

    state: tariffPlansDefaultState(),

    actions: {
        getAvailableTariffPlans ({state, commit}) {
            return TariffPlansService
                .getAvailableTariffPlans()
                .then((response) => {
                    commit(SET_AVAILABLE_TARIFF_PLANS, response.data)
                }).catch((error) => {
                    throw new Error(error)
                })
        }
    },
    mutations: {
        [SET_AVAILABLE_TARIFF_PLANS]: (state, tariffPlans) => {
            state.availableTariffPlansList = tariffPlans
        },
    },
    getters: {
        sortedTariffPlansList: (state) => {
            const tariffs = [...state.availableTariffPlansList].sort((a, b) => {
                if (a.price.amount > b.price.amount) {return 1}
                if (a.price.amount < b.price.amount) {return -1}
                return 0;
            })
            return tariffs
        },
        getTariffPlan: (state) => (tariffPlanId) => {
            const tariff = state.availableTariffPlansList.find(tariff => {
                return tariff.id === tariffPlanId
            })
            return tariff
        }
    }
}