<template>
  <v-navigation-drawer
    :value="drawer"
    @input="toggleDrawer"
    temporary
    height="auto"
    width="212"
    app
    class="main-nav"
  >
    <v-list class="main-nav__list" nav dense shaped>
      <template v-for="(link, i) in navLinks">
        <v-list-item
          v-if="!link.subNavLinks && !link.href"
          class="main-nav__item"
          :key="i"
          :to="$i18nRoute({ name: link.name })"
          exact
        >
          <v-list-item-title v-text="$t(link.title)" />
        </v-list-item>

        <v-list-item
          v-else-if="link.href"
          class="main-nav__item"
          :key="i"
          :href="link.href"
        >
          <v-list-item-title v-text="$t(link.title)" />
        </v-list-item>

        <v-list-group v-else-if="!link.href" class="main-nav__item" :key="i">
          <template v-slot:activator>
            <v-list-item-title>{{ $t(link.title) }}</v-list-item-title>
          </template>

          <v-list-item
            v-for="subLink in link.subNavLinks"
            :key="subLink.title"
            :to="$i18nRoute({ name: subLink.name })"
          >
            <v-list-item-title>{{ $t(subLink.title) }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { redirectToApp } from "utils";

export default {
  name: "MainNavigation",
  props: {
    navLinks: {
      type: Array,
      default: () => [],
    },
    drawer: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    redirectToApp,
    toggleDrawer(value) {
      this.$emit("updateDrawer", value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
$primary: #2ed3ae;
$secondary: #ff8438;
$tertiary: #ececec;
.main-nav {
  top: 15px !important;

  border-top-right-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  padding-left: 0;

  font-family: $body-font-family;
  text-transform: uppercase;

  background-image: url(../assets/images/icons/logo-letter-icon.svg);
  background-repeat: no-repeat;
  background-position: bottom 23px right 19px;
  background-size: 58px 58px;

  .v-list-item__title {
    font-size: 16px;
    line-height: 28px;
  }

  &__item.v-list-item--active {
    &:hover,
    &:active {
      color: $secondary !important;
    }
  }

  .v-list-item--active {
    color: $secondary;
  }

  .v-list-group--active {
    color: $secondary !important;
  }
}
</style>