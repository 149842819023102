export default function() {
    return {
        accountTariffPlanId: '',
        accountRegistered: {
            account: {
                id: '',
                name: '',
                masterUserId: ''
            },
            masterUser: {
                id: '',
                email: '',
                name: {
                    firstName: '',
                    lastName: ''
                },
                tariffPlanId: '',
                active: false
            }
        },
        error: ''
    }
}