<template>
  <v-app light>
    <app-header :nav-links="navLinks" @updateDrawer="(val) => (drawer = val)">
    </app-header>

    <app-navigation
      :nav-links="navLinks"
      :drawer="drawer"
      @updateDrawer="(val) => (drawer = val)"
    >
    </app-navigation>

    <v-main>
      <transition name="el-fade-in-linear" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <app-footer></app-footer>

    <snackbar></snackbar>

    <scroll-to-top></scroll-to-top>

    <vue-progress-bar></vue-progress-bar>
  </v-app>
</template>

<script>
/* Main Page */
import AppHeader from "./components/AppHeader";
import AppNavigation from "./components/AppNavigation";
import AppFooter from "./components/AppFooter";
import ScrollToTop from "./components/ScrollToTop.vue";
import Snackbar from "./components/Snackbar.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppNavigation,
    AppFooter,
    ScrollToTop,
    Snackbar,
  },
  data() {
    return {
      drawer: false,
      navLinks: [
        {
          title: "navigation.home",
          name: "home",
        },
        {
          title: "navigation.features",
          icon: "list",
          subNavLinks: [
            {
              title: "navigation.features-list.email-testing",
              name: "email-design-testing",
            },
            {
              title: "navigation.features-list.email-analytics",
              name: "email-analytic",
            },
            {
              title: "navigation.features-list.spam-testing",
              name: "inbox-spam-testing",
            },
            {
              title: "navigation.features-list.white-label-api",
              name: "white-label-api",
            },
            {
              title: "navigation.features-list.email-editor",
              name: "email-editor",
            },
          ],
        },
        {
          title: "navigation.pricing",
          name: "pricing",
        },
        {
          title: "navigation.blog",
          href: "http://blog.emailpreviewservices.com/",
        },
        {
          title: "navigation.about",
          name: "about",
        },
        {
          title: "navigation.contacts",
          name: "contact",
        },
      ],
    };
  },
  metaInfo() {
    return {
      title: this.$i18n.t("meta.title"),
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.$i18n.t("meta.description"),
        },
        {
          vmid: "description",
          name: "keywords",
          content: this.$i18n.t("meta.keywords"),
        },
      ],
    };
  },
  mounted() {
    this.$Progress.finish();
  },
  created() {
    this.$vuetify.theme.dark = this.$store.state.darkMode;
    this.$Progress.start();
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        this.$Progress.parseMeta(meta);
      }
      this.$Progress.start();
      next();
    });
    this.$router.afterEach((to, from) => {
      this.$Progress.finish();
    });
  },
};
</script>