import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import createPersistedState from "vuex-persistedstate";

import defaultState from "defaultStates";
/*Modules*/
import { accountModule } from "./modules/account/accountModule";
import { emailPreviewsShareModule } from "./modules/email-previews-share/emailPreviewsShareModule";
import { tariffPlanModule } from "./modules/tariff-plans/tariffPlansModule";
import { snackbarModule } from "./modules/snackbar/snackbarModule";

const store = new Vuex.Store({
	state: {
		darkMode: false,
		// locale: selectedLocale,
	},
	modules: {
		account: accountModule,
		emailPreviewsShare: emailPreviewsShareModule,
		tariffPlans: tariffPlanModule,
		snackbar: snackbarModule,
	},
	plugins: [createPersistedState()],
	mutations: {
		RESET_STORAGE: (state) => {
			Object.assign(state, defaultState());
		},
		SET_DARK_MODE: (state) => {
			state.darkMode = !state.darkMode;
		},
	},
});

export default store;
