<template>
  <v-app-bar app flat>

        <v-app-bar-nav-icon
          v-if="this.$vuetify.breakpoint.mdAndDown"
          @click.stop="toggleDrawer"
        >
          <v-icon>menu</v-icon>
        </v-app-bar-nav-icon>

        <router-link to="/">
          <v-img
            src="@/assets/images/eps-logo.png"
            :contain="true"
            :height="36"
            :width="202"
          />
        </router-link>

        <v-spacer />
        
        <template v-if="this.$vuetify.breakpoint.lgAndUp">
          <template v-for="(link, i) in navLinks">
            <v-btn
              v-if="!link.subNavLinks && !link.href"
              :key="i"
              :to="$i18nRoute({ name: link.name })"
              color="white"
              :style="link.name == 'home' ? 'margin-left: 140px': ''"
              text
              exact
              small
            >
              {{ $t(link.title) }}
            </v-btn>

            <v-menu v-else-if="!link.href" offset-y :key="i" open-on-hover>
              <template v-slot:activator="{ on }">
                <v-btn text v-on="on" small color="white">
                  {{ $t(link.title) }}
                  <v-icon x-small> keyboard_arrow_down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="subLink in link.subNavLinks"
                  :key="subLink.title"
                  :to="$i18nRoute({ name: subLink.name })"
                >
                  <v-list-item-title>
                    {{ $t(subLink.title) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
              v-else-if="link.href"
              :key="i"
              :href="link.href"
              text
              small
              color="white"
            >
              {{ $t(link.title) }}
            </v-btn>
          </template>
        </template>

        <v-spacer />

        <v-btn
          class="d-none d-md-block"
          @click="switchDarkMode"
          icon
          color="white"
        >
          <v-avatar>
            <v-icon>{{ darkMode ? "$sun" : "$moon" }}</v-icon>
          </v-avatar>
        </v-btn>

        <v-menu offset-y open-on-hover>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" text class="d-none d-md-block" color="white">
              <v-icon> translate </v-icon>
              <v-icon x-small> keyboard_arrow_down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              {{ $t("langs.translations") }}
            </v-list-item>

            <v-list-item
              v-for="(lang, i) in langs"
              :key="`locale${i}`"
              @click="switchLocale(lang.locale)"
            >
              <v-list-item-title>
                {{ lang.code }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-btn :href="appUrl" outlined color="secondary">
          {{ $t("navigation.login") }}
        </v-btn>

        <v-btn
          v-if="this.$vuetify.breakpoint.smAndUp"
          :to="$i18nRoute({ name: 'register' })"
          class="ml-2 start"
          color="primary"
          depressed
        >
          {{ $t("navigation.start-testing") }}
        </v-btn>

  </v-app-bar>
</template>

<script>
import { APP_URL } from "config";

export default {
  name: "MainHeader",
  data: () => ({
    appUrl: APP_URL,
  }),
  props: {
    navLinks: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    switchDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.darkMode = this.$vuetify.theme.dark;
    },
    switchLocale(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang;
        this.$router.push({
          params: { lang: lang },
        });
      }
    },
    toggleDrawer() {
      this.$emit("updateDrawer", true);
    },
  },
  computed: {
    darkMode: {
      get() {
        return this.$store.state.darkMode;
      },
      set() {
        this.$store.commit("SET_DARK_MODE");
      },
    },
    langs() {
      return [
        {
          code: this.$t("langs.english"),
          locale: "en",
        },
        {
          code: this.$t("langs.polish"),
          locale: "pl",
        },
        {
          code: this.$t("langs.ukrain"),
          locale: "ua",
        },
        {
          code: this.$t("langs.russian"),
          locale: "ru",
        },
      ];
    },
  },
};
</script>