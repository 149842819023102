import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { API_ROOT } from "config";

import {
	AccountResource,
	EmailPreviewsSharingResource,
	TariffPlansResource,
} from "./resources";

const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.axios.defaults.baseURL = API_ROOT;
	},

	query(resource, params) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.get(`${url}`, params).catch((error) => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},

	get(resource, params) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.get(`${url}`, { params }).catch((error) => {
			return Promise.reject(error);
		});
	},

	post(resource, params) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.post(`${url}`, params);
	},

	update(resource, params) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.put(`${url}`, params);
	},

	put(resource, params) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.put(`${url}`, params);
	},
	delete(resource) {
		let url = resource.url ? resource.url : resource;
		return Vue.axios.delete(url).catch((error) => {
			throw new Error(`[RWV] ApiService ${error}`);
		});
	},
};
export default ApiService;

export const AccountService = {
	accountRegister(user) {
		return ApiService.post(AccountResource.accountRegister(), user);
	},
	accountSocialRegister(socialData) {
		let {
			providerId,
			tariffPlanId,
			providerRedirectTo,
			paymentToken,
			code,
		} = socialData;
		let headers = { "X-Requested-With": "XMLHttpRequest" };
		let { url } = AccountResource.accountSocialRegister(
			providerId,
			tariffPlanId,
			providerRedirectTo,
			paymentToken
		);
		return Vue.axios.get(url, { params: { code }, headers }).catch((error) => {
			return Promise.reject(error);
		});
	},
	accountActivate(activationCode) {
		return ApiService.post(AccountResource.accountActivate(), activationCode);
	},
	accountActivateRequest(email) {
		return ApiService.post(AccountResource.accountActivateRequest(), email);
	},
};

export const EmailPreviewsSharingService = {
	getSharedEmailPreview(shareCode) {
		return ApiService.get(
			EmailPreviewsSharingResource.getSharedEmailPreview(),
			{ shareCode: shareCode }
		);
	},
};

export const TariffPlansService = {
	getAvailableTariffPlans() {
		return ApiService.get(TariffPlansResource.getAvailableTariffPlans());
	},
};
