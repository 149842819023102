<template>
	<v-snackbar v-model="show" v-bind="options" :color="color">
		{{ message }}
		<v-btn text color="white" @click.native="show = false">Close</v-btn>
	</v-snackbar>
</template>

<script>
import {mapState} from 'vuex'

export default {
	data() {
		return {
			show: false,
			message: '',
			color: '',
			options: {
				timeout: 5000,
				top: true,
				bottom: false,
				right: true,
				left: false,
				'multi-line': false,
			}
		}
	},
	computed: {
		...mapState('snackbar', [
			'snack',
			'type',
			'snackOptions'
		]),
	},
	created: function () {
		this.$store.watch(state => [this.snack, this.type, this.snackOptions], () => {
			const msg = this.snack

			if (msg !== '') {
				this.show = true
				this.message = this.$store.state.snackbar.snack
				this.$store.commit('snackbar/setSnack', '')
			}

			const type = this.type
			switch (type) {
				case 'success':
					this.color = 'primary'
					break;
				case 'info':
					this.color = 'info'
					break;
				case 'error':
					this.color = 'error'
					break;
			}
      
			this.$store.commit('snackbar/setSnackType', '')
			const opt = this.snackOptions
			if (opt) {
				_.mapKeys(opt, (value, key) => {
					if (_.has(this.options, key)) {
						_.set(this.options, key, value)
					}
				})
				this.$store.commit('snackbar/setSnackOptions', null)
			}
		})
	}
}
</script>