import Vue from "vue";
import Router from "vue-router";
import store from "store";
import Meta from "vue-meta";
import { Trans } from "@/plugins/Translation";

const publicOptions = [
	{
		path: "/",
		name: "home",
		component: "home/Home",
	},
	{
		path: "/(/+)",
		redirect: "/",
	},
	{
		path: "user/register",
		name: "register",
		component: "signup/SignUp",
	},
	{
		path: "user/user-success",
		component: "signup/SignUpSuccess",
		name: "sign-up-success",
		beforeEnter: (to, from, next) => {
			let userRegistered = store.getters["account/getIsAccountRegistered"];
			if (!userRegistered) {
				next({ path: "/" });
			} else {
				next();
			}
		},
	},
	{
		path: "pricing",
		name: "pricing",
		component: "pricing/Pricing",
	},
	{
		path: "features/email-design-testing",
		name: "email-design-testing",
		component: "features/EmailDesignTesting",
	},
	{
		path: "features/email-analytic",
		name: "email-analytic",
		component: "features/EmailAnalytics",
	},
	{
		path: "features/inbox-spam-testing",
		name: "inbox-spam-testing",
		component: "features/InboxSpamTesting",
	},
	{
		path: "features/white-label-api",
		name: "white-label-api",
		component: "features/WhiteLabelApi",
	},
	{
		path: "features/email-editor",
		name: "email-editor",
		component: "features/EmailEditor",
	},
	{
		path: "articles/what-is-email-testing",
		name: "what-is-email-testing",
		component: "articles/WhatIsEmailTesting",
	},
	{
		path: "contact",
		name: "contact",
		component: "contacts/Contacts",
	},
	{
		path: "about",
		name: "about",
		component: "about/About",
	},
	{
		path: "policy/:name?",
		name: "policy",
		component: "policy/Policy",
	},
	{
		path: "email-preview-shared/:shareCode",
		name: "email-preview-shared",
		component: "email-preview-shared/EmailPreviewShared",
	},
	{
		path: "404",
		name: "404",
		component: "404",
	},
	{
		path: "*",
		component: "404",
	},
];

Vue.use(Router);

Vue.use(Meta, {
	keyName: "metaInfo",
	attribute: "data-vue-meta",
	tagIDKeyName: "vmid",
});

function load(component) {
	return () => import(`@/components/landing/${component}.vue`);
}

const routes = publicOptions.map((route) => {
	return {
		...route,
		component: () => import(`@/components/landing/${route.component}.vue`),
	};
});

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/user/user-activation/:activationCode",
			name: "user-activation",
			component: load("signup/SignUpActivation"),
		},
		{
			path: "/:lang",
			component: {
				template: "<router-view></router-view>",
			},
			beforeEnter: Trans.routeMiddleware,
			children: routes,
		},
		{
			path: "*",
			redirect: (to) => {
				return Trans.getUserSupportedLang();
			},
		},
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

export default router;
