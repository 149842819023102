import store from 'store'
import { APP_URL } from 'config'

export function redirectToApp() {
    window.location.href = APP_URL
}

export function camelCase(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
        return index == 0 ? letter.toLowerCase() : letter.toUpperCase();
    }).replace(/\s+/g, '');
}

export function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}


export function snackbar(message, type, options) {
    store.commit('snackbar/setSnack', message)
    store.commit('snackbar/setSnackOptions', options)
    store.commit('snackbar/setSnackType', type)
}

export function extractErrorMsg(response) {
    let error = response.data
    let messages = '';
    if(error.errorMessage) {
        messages = error.errorMessage
    } else {
        messages = 'Unknown error'
    }
    return messages;
}

export function redirect(url) {
    window.location.href = url;
}

export function cloneObject(obj) {
    return JSON.parse(JSON.stringify(obj));
}

