import {AccountService} from 'api'
import {snackbar, extractErrorMsg, redirectToApp} from 'utils'
import accountDefaultState from 'defaultStates/account'

import {
	SET_ACCOUNT_REGISTERED,
	SET_ACCOUNT_TARIFF_PLAN_ID,
	SET_ACCOUNT,
	SET_LOADING
} from '../mutations.type'

export const accountModule = {
	
	namespaced: true,
	
	state: accountDefaultState(),
	
	actions: {
		accountRegister({state, commit, dispatch}, user = {}) {
			return AccountService
				.accountRegister(user)
				.then((response) => {
					commit(SET_ACCOUNT_REGISTERED, response.data)
					dispatch('accountActivateRequest', {email: response.data.masterUser.email})
				}).catch((error) => {
					let email = ((((error || {}).response || {}).data || {}).parameters || {}).email
					if (email) {
						snackbar(email, 'error')
					} else {
						snackbar(extractErrorMsg(error.response), 'error')
					}
					return Promise.reject(error.response)
				})
		},
		accountSocialRegister({state, commit}, socialData) {
			return AccountService
				.accountSocialRegister(socialData)
				.then(() => {
					snackbar('Account created successfully!', 'success')
					redirectToApp()
				}).catch((error) => {
					snackbar(extractErrorMsg(error.response), 'error')
					throw new Error(error)
				})
		},
		accountActivate({state, commit}, activationCode) {
			return AccountService
				.accountActivate(activationCode)
				.then(() => {
				}).catch((error) => {
					snackbar(extractErrorMsg(error.response), 'error')
					throw new Error(error)
				})
		},
		accountActivateRequest({state, commit}, email) {
			return AccountService
				.accountActivateRequest(email)
				.then(() => {
				}).catch((error) => {
					snackbar(extractErrorMsg(error.response), 'error')
					throw new Error(error)
				})
		},
		accountSetTariffPlanId({commit}, tariffPlanId) {
			commit(SET_ACCOUNT_TARIFF_PLAN_ID, tariffPlanId)
		},
	},
	
	mutations: {
		[SET_ACCOUNT_REGISTERED]: (state, account) => {
			state.accountRegistered = account
		},
		[SET_ACCOUNT_TARIFF_PLAN_ID]: (state, tariffPlanId) => {
			state.accountTariffPlanId = tariffPlanId
		},
		[SET_LOADING]: (state, loading) => {
			state.loading = loading
		}
	},
	getters: {
		getIsAccountRegistered: (state) => {
			return state.accountRegistered.account.id
		},
		getAccountTariffPlanId: (state) => {
			return state.accountTariffPlanId
		}
	}
}