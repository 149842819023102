import account from './account'
import emailPreviewsShare from './emailPreviewsShare'
import tariffPlans from './tariffPlans'

export default function () {
    return Object.assign({
        auth: auth(),
        account: account(),
        emailPreviewsShare: emailPreviewsShare(),
        tariffPlans: tariffPlans()
    })
}