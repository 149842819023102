<template>
  <v-scale-transition>
    <v-btn
      v-show="btnShow"
      v-scroll="onScroll"
      transition="scale-transition"
      @click="$vuetify.goTo(0, scrollOptions)"
      fab
      bottom
      fixed
      right
      color="primary"
      style="transform-origin: center center 0px; right: 26px; bottom: 170px;"
    >
      <v-icon class="d-flex" color="#ffffff">keyboard_arrow_up</v-icon>
    </v-btn>
  </v-scale-transition>
</template>

<script>
export default {
  data() {
    return {
      offsetTop: 0,
      scrollOptions: {
        duration: 400,
        easing: 'easeInOutCubic',
        offset: 0,
      },
    }
  },
  computed: {
    btnShow() {
      return this.offsetTop > 400? true : false
    }
  },
  methods: {
    onScroll () {
      this.offsetTop = window.pageYOffset || document.documentElement.scrollTop
    }
  },
}
</script>