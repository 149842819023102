import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { DEFAULT_LANGUAGE, FALLBACK_LANGUAGE } from '@/constants/trans'
import en from '@/lang/en.json'
import ru from '@/lang/ru.json'
import pl from '@/lang/pl.json'
import ua from '@/lang/ua.json'

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: DEFAULT_LANGUAGE, // set locale
    fallbackLocale: FALLBACK_LANGUAGE,
    messages: { en, ru, pl, ua } // set locale messages
})