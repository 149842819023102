export const AccountResource = {
    accountRegister() { return { url: `account/register` } },
    accountSocialRegister(providerId, tariffPlanId, providerRedirectTo, paymentToken) {
        return {
            url: `account/register/social/${providerId}?tariffPlanId=${tariffPlanId}&providerRedirectTo=${providerRedirectTo}&paymentToken=${paymentToken}`,
        }
    },
    accountActivate() { return { url: `account/users/activate` } },
    accountActivateRequest() {  return  { url: `account/users/activate/request` } }
}

export const EmailPreviewsSharingResource = {
    getSharedEmailPreview() { return {  url: `previews/shared` } }
}

export const TariffPlansResource = {
    getCurrentUserTariffPlan() { return { url: `tariff` } },
    changeUserTariffPlan() { return { url: `tariff` } },
    getAvailableTariffPlans() { return { url: `tariff/available` } }
}