export default function () {
	return {
		availableTariffPlansList: [{
			id: '',
			name: '',
			description: '',
			price: {
				amount: '',
				currencyCode: ''
			},
			period: {
				interval: '',
				count: ''
			},
			trialDays: 0,
			enabled: true
		}]
	}
}