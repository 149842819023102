import {EmailPreviewsSharingService} from 'api'
import emailPreviewsShareDefaultState from 'defaultStates/emailPreviewsShare'


import {
	SET_EMAIL_PREVIEW_SHARED,
	RESET_EMAIL_PREVIEW_SHARED,
} from '../mutations.type'

export const emailPreviewsShareModule = {
	namespaced: true,
	
	state: emailPreviewsShareDefaultState(),
	
	actions: {
		getSharedEmailPreview: function ({state, commit}, shareCode) {
			return EmailPreviewsSharingService
				.getSharedEmailPreview(shareCode)
				.then((response) => {
					commit(SET_EMAIL_PREVIEW_SHARED, response.data)
				}).catch((error) => {
					commit(RESET_EMAIL_PREVIEW_SHARED)
					throw new Error(error)
				})
		}
	},
	mutations: {
		[SET_EMAIL_PREVIEW_SHARED]: (state, emailPreviewShared) => {
			state.emailPreviewShared = emailPreviewShared
		},
		[RESET_EMAIL_PREVIEW_SHARED]: (state) => {
			Object.assign(state.emailPreviewShared, emailPreviewsShareDefaultState().emailPreviewShared)
		},
	},
}
