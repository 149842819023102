/* Vue */
import Vue from "vue";
import { i18n } from "@/plugins/i18n";
import router from "router";
import store from "store";
import { Trans } from "./plugins/Translation";
import vuetify from "@/plugins/vuetify";

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans);

/* Vue Slick Carousel */
// import VueSlickCarousel from "vue-slick-carousel";
// Vue.use(VueSlickCarousel);

import VueProgressBar from "vue-progressbar";

Vue.config.productionTip = true;

/* App component */
import App from "./App";

import VeeValidate, { Validator } from "vee-validate";


/* VeeValidate config */
const config = {
	errorBagName: "errors", // change if property conflicts.
	fieldsBagName: "fields",
	delay: 500,
	locale: "en",
	dictionary: null,
	strict: true,
	classes: false,
	classNames: {
		touched: "touched", // the control has been blurred
		untouched: "untouched", // the control hasn't been blurred
		valid: "valid", // model is valid
		invalid: "invalid", // model is invalid
		pristine: "pristine", // control has not been interacted with
		dirty: "dirty", // control has been interacted with
	},
	events: "input|blur",
	inject: false,
};
/* VeeValidate config end */

/* App sass */
import "@/assets/style/app.scss";

/* VeeValidate */
Vue.use(VeeValidate, config);

/*Vue progress bar config*/
const progressBarOptions = {
	color: "#2ed3ae",
	failedColor: "red",
	height: "2px",
	thickness: "2px",
};

Vue.use(VueProgressBar, progressBarOptions);
/*Vue progress bar config end*/

/*Api Service*/
import ApiService from "api";
ApiService.init();

/*Vue timers*/
import VueTimers from "vue-timers";
Vue.use(VueTimers);

/*Vue AsyncComputed*/
import AsyncComputed from "vue-async-computed";
Vue.use(AsyncComputed, {
	default: undefined,
});

// /*Google signIn*/
// import GSignInButton from '@/social-auth/google-auth'
// Vue.use(GSignInButton);

// /*Facebook signIn*/
// import FBSignInButton from '@/social-auth/facebook-auth'
// Vue.use(FBSignInButton);

const app = new Vue({
	vuetify,
	el: "#app",
	created: function() {
		window.Vue = this;
	},
	i18n,
	router,
	store,
	render: (h) => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
});

export default app;
