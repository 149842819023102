export default function() {
    return {
	    emailPreviewShared: {
		    id: '',
		    name: '',
		    createdAt: '',
		    createdBy: {
			    accountId: '',
			    userId: '',
			    email: '',
			    firstName: '',
			    lastName: ''
		    },
		    lastModifiedAt: '',
		    lastModifiedBy: {
			    accountId: '',
			    userId: '',
			    email: '',
			    firstName: '',
			    lastName: ''
		    },
		    subject: '',
		    body: '',
		    previews: [
			    {
				    deviceKey: '',
				    status: '',
				    preview: {
					    original: '',
					    thumbnail: ''
				    }
			    }
		    ],
	    },
    }
}